import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import RequestForProposal from "../components/RequestForProposal";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { InlineWidget } from "react-calendly";


export default function RfpPage() {
  return (
    <>
    
    <SiteWrapper activeItem={ACTIVE_PAGE.RFP}>
      <Seo title="Request For Proposal | Robotic Imaging" />

      <header className="site__header"></header>


        <RequestForProposal />


      <div className="text-container">
        <h2>Schedule Now</h2>
      </div>
      
      <div className="rfp_content">
        <div className="rfp__wrapper">
          <div className="rfp_leftColumn">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FSteps%20for%20a%20Successful%20Scanning%20Survey%20Graphic.svg?alt=media&token=eb9c7e3a-7080-4aa5-abf3-a9650f670211"
              className="rfp_infographic"
              alt="infographic"
            />
          </div>
          <div className="rfp_rightColumn">
            <InlineWidget
              url="https://calendly.com/roboticimaging"
              styles={{
              marginTop: "0px",
              maxWidth: '100%',
              maxHeight: '600px',
              height: 'calc(100vh - $nav-height - 96px) !important',
              minWidth: '600px',
              objectFit: 'contain'
            }}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
    </>
  );
}
